import { Operators } from 'domain/general.types';
import Filter from './domain/Filter';

export type Ptr = string; // <category_subject_xxxx.actioncount#2<agg>>

export interface FilterAPI {
  op: Operators;
  val: any;
  ptr: Ptr;
  identifier?: string;
}

export interface Node<T = Filter | FilterAPI> {
  tree?: Tree<T>;
  leaf?: Leaf<T>;
}

export interface Tree<T = Filter | FilterAPI> {
  nodes: Node<T>[];
  op: FilterGroupOperation;
}

export enum FilterGroupOperation {
  And = 'and',
  OR = 'or',
  None = '',
}

export interface Leaf<T = Filter | FilterAPI> {
  filters: T[];
  op: FilterGroupOperation;
}

export interface BriefCohort {
  id: number;
  name: string;
  tree: string;
}
