import React, { useCallback, useEffect, useState } from 'react';

import { faArrowDown, faArrowUp, faExpand, faRefresh, faXmark } from '@fortawesome/free-solid-svg-icons';

import { chartLineColor1, CHART_COLORS } from 'styles/colors';

import { useAnalyticsContext } from '../../context/useAnalyticsContext';
import { useServicesContext } from 'hooks/useServicesContext';
import { useToast } from 'hooks';

import { CFRole } from 'domain/general.types';
import { Trait, TraitCode, TraitSubject } from 'domain/traits.types';
import { AggLevel, AggLevelInverseMapping, Series, TimeSeriesItem } from 'domain/stats.types';

import { AuthAction } from 'services/authorization.service';
import { createTraitCode, getDisplayName, getIdentifier } from 'services/traits/helpers.traits';
import { CohortID } from 'services/cohort/cohort.types';

import ProtectedElement from 'connected-components/ProtectedElement';

import CFButton from 'components/buttons/CFButton';
import CFLineChart, { ChartControl, ScaleType } from 'components/charts/CFLineChart';
import { ToastType } from 'components/CFToast/types';
import CFSwitch from 'components/CFSwitch';

import { belongToSameGap } from 'helpers/dates';
import { NormalizationType } from '../../types';
import CFPortal, { ContentPosition } from 'components/CFPortal';

import ExtendedTimeserie from '../ExtendedTimeserie';
import { TraitAction } from '../../hooks/useInitializedTraits';
import { Ptr } from 'services/cohort/cohort.types.api';

const TRAIT_SUBJECT_COUNT = (aggLevel: AggLevel, subject: TraitSubject) =>
  `gdt_${subject}_general.sub_count#1${AggLevelInverseMapping[aggLevel]}`;

const TRAIT_ACTIVE_USERS_COUNT = (aggLevel: AggLevel, subject: TraitSubject) =>
  `gdt_${subject}_general.active_user_count#1${AggLevelInverseMapping[aggLevel]}`;

interface Props {
  isFirst: boolean;
  isLast: boolean;
  trait: Trait;
}

const TimeSerie = ({ isFirst, isLast, trait }: Props) => {
  const { backfillService, cohortService } = useServicesContext();
  const { explorationMode, traitsState } = useAnalyticsContext();
  const [isBackfilling, setIsBackfilling] = useState(false);

  const { addToast } = useToast();

  const [normalizationSerie, setNormalizationSerie] = useState<Record<CohortID, TimeSeriesItem[]>>({});

  const [timeseries, setTimeSeries] = useState<Record<CohortID, Series>>({});
  const [normalized, setNormalized] = useState(false);
  const [extended, setExtended] = useState(false);

  const { aggLevel, subject, startDate, endDate, selectedCohortIDs, getSerie, normalizationType, dispatchTraitAction } =
    useAnalyticsContext();

  useEffect(() => {
    (async () => {
      const newTimeSeries: Record<CohortID, Series> = {};

      for (let idx = 0; idx < selectedCohortIDs.length; idx++) {
        const cohortId = selectedCohortIDs[idx];
        const rawSerie = await getSerie(cohortId, createTraitCode(trait));
        let serie = rawSerie;

        if (normalized && normalizationSerie[parseInt(cohortId)] !== undefined) {
          serie = rawSerie.map((serie, i) => {
            const newValue = normalizationSerie[parseInt(cohortId)][i].value
              ? serie.value / normalizationSerie[parseInt(cohortId)][i].value
              : 0;

            return { ...serie, value: newValue };
          });
        }

        newTimeSeries[parseInt(cohortId)] = {
          name: cohortService.getCohort(cohortId)?.name || 'unknown',
          items: serie,
          color: CHART_COLORS[idx] || chartLineColor1,
        };
      }

      setTimeSeries(newTimeSeries);
    })();
  }, [selectedCohortIDs, normalizationType, normalizationSerie]);

  useEffect(() => {
    (async () => {
      if (!normalized) {
        setNormalizationSerie({});
        return;
      }

      const normalizationTrait =
        normalizationType === NormalizationType.CohortSubjects
          ? TRAIT_SUBJECT_COUNT(aggLevel, subject)
          : TRAIT_ACTIVE_USERS_COUNT(aggLevel, subject);

      const userSubjectTimeseriesPromises = selectedCohortIDs.map((cohortID) => {
        return getSerie(cohortID, normalizationTrait);
      });

      const userSubjectTimeseries = await Promise.all(userSubjectTimeseriesPromises);

      const normalizationSerie: Record<CohortID, TimeSeriesItem[]> = {};

      selectedCohortIDs.forEach((cohortId, i) => {
        normalizationSerie[parseInt(cohortId)] = userSubjectTimeseries[i];
      });

      setNormalizationSerie(normalizationSerie);
    })();
  }, [selectedCohortIDs, aggLevel, subject, normalizationType, normalized]);

  const handleChartClose = useCallback(async (traitCode: TraitCode) => {
    dispatchTraitAction({ ptr: traitCode, action: TraitAction.REMOVE });
  }, []);

  const handleChartUp = useCallback(() => {
    dispatchTraitAction({ ptr: trait.addr.ptr, action: TraitAction.UP });
  }, [trait]);

  const handleChartDown = useCallback(() => {
    dispatchTraitAction({ ptr: trait.addr.ptr, action: TraitAction.DOWN });
  }, [trait]);

  const handleBackfill = useCallback(
    (trait: Trait) => {
      const traitCode = getIdentifier(trait);

      setIsBackfilling(true);

      addToast(`Started to backfill trait ${getDisplayName(trait)}`, ToastType.INFO);

      backfillService
        .backfillTrait(startDate, endDate, [], true, traitCode)
        .catch(() => {
          addToast('Error backfilling', ToastType.ERROR);
        })
        .then(() => {
          setIsBackfilling(false);
        });
    },
    [startDate, endDate]
  );

  const handleNormalize = useCallback((normalize: boolean) => {
    setNormalized(normalize);
  }, []);

  const handleCloseExtendedChart = useCallback(() => {
    setExtended(false);
  }, []);

  console.log('[aec] ', traitsState.traits[aggLevel] as Ptr[]);
  return (
    <>
      {extended && (
        <CFPortal mode={ContentPosition.Center} onClickOutside={handleCloseExtendedChart}>
          <ExtendedTimeserie trait={trait} timeseries={timeseries} />
        </CFPortal>
      )}

      <CFLineChart
        testId={`trait-chart`}
        scale={ScaleType.Linear}
        showScaleControl={trait?.meta.unit !== 'ratio'}
        yLabel={trait?.meta.display_unit}
        units={trait?.meta.display_unit ?? ''}
        title={getDisplayName(trait)}
        description={trait?.meta.description}
        color={chartLineColor1}
        data={Object.values(timeseries)}
        aggregationLevel={aggLevel}
        // Remove CFLineChart loader, because CFLazyWrapper already provides a loader
        isLoading={false}
        showLegend={false}
        step={0.01}
        highlightLast={belongToSameGap(endDate, aggLevel)}
      >
        <div className="line-chart-controls">
          <CFSwitch checked={false} onChange={handleNormalize} />
          <span>Normalized</span>
        </div>

        <ChartControl>
          <CFButton
            onClick={() => setExtended(true)}
            description="Expand"
            value=""
            iconName={faExpand}
            role={CFRole.OnlyIcon}
          />
        </ChartControl>

        {!isLast && (
          <ChartControl>
            <CFButton
              testId={`arrow-down-${getIdentifier(trait)}`}
              onClick={() => handleChartDown()}
              description="Move down this chart"
              value=""
              iconName={faArrowDown}
              role={CFRole.OnlyIcon}
              disabled={explorationMode}
            />
          </ChartControl>
        )}

        {!isFirst && (
          <ChartControl>
            <CFButton
              onClick={() => handleChartUp()}
              description="Move up this chart"
              value=""
              iconName={faArrowUp}
              role={CFRole.OnlyIcon}
              testId={`arrow-up-${getIdentifier(trait)}`}
              disabled={explorationMode}
            />
          </ChartControl>
        )}

        <ProtectedElement authAction={AuthAction.Backfill}>
          <ChartControl>
            <CFButton
              onClick={() => handleBackfill(trait)}
              disabled={isBackfilling || explorationMode}
              animate={isBackfilling}
              description="Recompute values"
              value=""
              iconName={faRefresh}
              role={CFRole.OnlyIcon}
              testId={`chart-backfill-${isBackfilling ? 'backfilling-' : ''}${getIdentifier(trait)}`}
            />
          </ChartControl>
        </ProtectedElement>

        <ChartControl>
          <CFButton
            testId={`remove-chart-${getIdentifier(trait)}`}
            description="Remove chart"
            onClick={() => handleChartClose(createTraitCode(trait.addr))}
            value=""
            iconName={faXmark}
            role={CFRole.OnlyIcon}
            disabled={explorationMode}
          />
        </ChartControl>
      </CFLineChart>
    </>
  );
};

export default TimeSerie;
