import { useServicesContext } from 'hooks/useServicesContext';
import { useEffect, useState } from 'react';
import { FixedNudgeSchedule } from 'services/intervention/intervention.types';

interface Props {
  id: number;
}

const useScheduler = ({ id }: Props) => {
  const { schedulingService } = useServicesContext();
  const [fixedNudgeSchedule, setFixedNudgeSchedule] = useState<FixedNudgeSchedule>();

  useEffect(() => {
    const scheduler = schedulingService.getById(id);

    if (!scheduler) {
      return;
    }

    setFixedNudgeSchedule(scheduler);
  }, [id]);

  useEffect(() => {
    schedulingService.subscribe(id, (schedule) => {
      setFixedNudgeSchedule(schedule);
    });
  }, [id]);

  return fixedNudgeSchedule;
};

export default useScheduler;
