import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { chartLineColor1, CHART_COLORS } from 'styles/colors';

import { getCurrentProject } from 'services/session/session.service';

import SelectedTags, { TagValue } from 'components/SelectedTags';
import { ToastType } from 'components/CFToast/types';
import DatetimeRangePicker from 'components/DateTime/DatetimeRangePicker';
import { DateRange, DefinedRange } from 'components/DateTime/DatetimeRangePicker/types';
import { defaultRanges } from 'components/DateTime/DatetimeRangePicker/default';

import { Tabs } from 'views/model/tabs';
import useModel from 'views/model/hooks/useModel';

import useToast from 'hooks/useToast';
import { useServicesContext } from 'hooks/useServicesContext';

import { Trait, TraitName } from 'domain/traits.types';

import ListViewer from 'connected-components/ListViewer';
import ModelDetailLayout from '../layout';

import { getEndDate, oneWeekAgo } from 'helpers/dates';
import { toString as dateToString } from 'helpers/dates';

import { SubjectId } from 'domain/general.types';
import { createTraitCode, getDisplayName, getTraitName } from 'services/traits/helpers.traits';
import CFLineChart, { ScaleType } from 'components/charts/CFLineChart';
import { AggLevel, TimeSeriesItem } from 'domain/stats.types';

import { getMLTTimeseries } from './service';

import './model-ml-traits.scss';

const ModelMlTraits = () => {
  const { addToast } = useToast();
  const model = useModel();
  const project = getCurrentProject();

  const defaultStartDate = project?.created_at ?? dateToString(oneWeekAgo());
  const defaultEndDate = getEndDate(project?.created_at);

  const [subjects, setSubjects] = useState<SubjectId[]>([]);

  const [traits, setTraits] = useState<Trait[]>([]);

  const [startDate, setStartDate] = useState<string>(defaultStartDate);
  const [endDate, setEndDate] = useState<string>(defaultEndDate);

  const [timeSeries, setTimeSeries] = useState<Record<TraitName, Record<SubjectId, TimeSeriesItem[]>>>({});

  const defaultDateRanges: DefinedRange[] = [
    ...defaultRanges,
    {
      label: 'Full history',
      startDate: moment(project?.created_at ?? 0).toDate(),
      endDate: new Date(),
    },
  ];

  const { modelService, traitSessionService } = useServicesContext();

  useEffect(() => {
    if (!model) {
      return;
    }

    modelService
      .getMachineLearningTraits()
      .then((traits) => {
        setTraits(traits);
      })
      .catch(() => {
        addToast('Machine learning traits not available', ToastType.ERROR);
      });
  }, [model]);

  useEffect(() => {
    (async () => {
      if (!model) {
        return;
      }

      const points = Object.keys(model.schedule.slots || {});

      const timeseries = await getMLTTimeseries(traitSessionService, points, model?.definition.id, traits, subjects);

      setTimeSeries(timeseries);
    })();
  }, [model, subjects, startDate, endDate, traits]);

  const handleRangeChange = useCallback((dateRange: DateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
      setStartDate(dateToString(dateRange.startDate));
      setEndDate(dateToString(dateRange.endDate));
    }
  }, []);

  const unselectUser = (subject: SubjectId) => {
    const index = subjects.indexOf(subject);

    if (index === -1) {
      return;
    }

    const newUsers = [...subjects];

    newUsers.splice(index, 1);

    setSubjects(newUsers);
  };

  const selectUser = (subject: SubjectId) => {
    setSubjects((subjects) => [...subjects, subject]);
  };

  const handleRemoveUserFromTag = useCallback(
    (tagValue: TagValue) => {
      unselectUser(tagValue.value);
    },
    [subjects]
  );

  const handleUsersError = useCallback(() => {
    addToast('Model users not available', ToastType.ERROR);
  }, []);

  const handleAddSubjectFromList = useCallback(
    (subjectId: SubjectId) => {
      if (subjects.includes(subjectId)) {
        unselectUser(subjectId);

        return;
      }

      selectUser(subjectId);
    },
    [subjects]
  );

  return (
    <ModelDetailLayout className="model-ml-traits" tab={Tabs.MlTraits}>
      <div className="model-ml-traits__header">
        <SelectedTags
          label="Users"
          values={subjects.sort().map((uid, idx) => ({
            value: uid,
            color: CHART_COLORS[idx] || chartLineColor1,
          }))}
          onRemove={handleRemoveUserFromTag}
          sticky={false}
        />

        <DatetimeRangePicker
          onChange={handleRangeChange}
          definedRanges={defaultDateRanges}
          initialDateRange={{
            startDate: new Date(defaultStartDate),
            endDate: new Date(defaultEndDate),
          }}
          maxDate={new Date(defaultEndDate)}
          showTime={false}
        />
      </div>

      <ListViewer
        sampleId={model?.definition.id}
        selectedItems={subjects}
        handleError={handleUsersError}
        handleClick={handleAddSubjectFromList}
      />

      {traits.map((trait) => {
        return (
          <div key={createTraitCode(trait)}>
            <CFLineChart
              testId={`trait-chart`}
              scale={ScaleType.Linear}
              showScaleControl={trait?.meta.unit !== 'ratio'}
              yLabel={trait?.meta.display_unit}
              units={trait?.meta.display_unit ?? ''}
              title={getDisplayName(trait)}
              description={trait?.meta.description}
              color={chartLineColor1}
              data={Object.keys(timeSeries[getTraitName(trait)] || {})
                .sort()
                .map((subjectId, idx) => ({
                  name: subjectId,
                  items: timeSeries[getTraitName(trait)][subjectId] || [],
                  color: CHART_COLORS[idx] || chartLineColor1,
                }))}
              aggregationLevel={AggLevel.Day}
              // Remove CFLineChart loader, because CFLazyWrapper already provides a loader
              isLoading={false}
              showLegend={false}
            />
          </div>
        );
      })}
    </ModelDetailLayout>
  );
};

export default ModelMlTraits;
