import React, { useCallback, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';

import { getEdgeDateRanges, getRecurringDatesForMonth } from 'helpers/dates';

import { CFRole } from 'domain/general.types';

import { AuthAction, isAllowedTo } from 'services/authorization.service';

import SchedulingBuilder, { InterventionSchedulingRef } from 'connected-components/SchedulingBuilder';

import WeekViewer from 'components/DateTime/WeekViewer';
import Month from 'components/DateTime/DatetimePicker/Month';
import { NavigationAction } from 'components/DateTime/DatetimeRangePicker/types';
import CFButton from 'components/buttons/CFButton';
import CFModal from 'components/CFModal';
import CFTitledSection, { SectionAction } from 'components/CFTitledSection';
import CFEditButton from 'components/buttons/CFEditButton';

import { useServicesContext } from 'hooks/useServicesContext';
import useScheduler from 'views/useScheduler';

import './schedule.scss';

interface Props {
  scheduleId: number;
  className?: string;
}

const Schedule = ({ scheduleId, className }: Props) => {
  const schedulingRef = useRef<InterventionSchedulingRef>() as React.MutableRefObject<InterventionSchedulingRef>;
  const { schedulingService } = useServicesContext();

  const fixedNudgeSchedule = useScheduler({ id: scheduleId });

  const [week, setWeek] = useState(dayjs().week());

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [editing, setEditing] = useState(false);
  const year = useMemo(() => dayjs().year(), []);

  const handleMonthNavigate = (action: NavigationAction) => {
    if (action === NavigationAction.Next) {
      const nextMonth = dayjs(currentDate).add(1, 'month');

      setCurrentDate(nextMonth.toDate());
    } else {
      const prevMonth = dayjs(currentDate).subtract(1, 'month');

      setCurrentDate(prevMonth.toDate());
    }
  };

  const handleClickOnDay = useCallback((day: Date) => {
    setWeek(dayjs(day).week());
    setSelectedDate(day);
  }, []);

  const schedulingSubTitle = useMemo(() => {
    if (!fixedNudgeSchedule) {
      return '';
    }

    const { startDate, endDate } = getEdgeDateRanges(fixedNudgeSchedule);

    return `Schedules for this intervention run from ${startDate.format('YYYY-MM-DD')} through ${endDate.format(
      'YYYY-MM-DD'
    )}, on ${fixedNudgeSchedule?.definition?.tz || endDate.format('Z')} time zone.`;
  }, [fixedNudgeSchedule]);

  const timestamps = useMemo(() => {
    if (!fixedNudgeSchedule) {
      return [];
    }

    if (fixedNudgeSchedule.definition.time_points?.pts) {
      return fixedNudgeSchedule.definition.time_points?.pts;
    } else if (fixedNudgeSchedule.definition.recurring?.pairs) {
      return Array.from(
        new Set(
          getRecurringDatesForMonth(fixedNudgeSchedule.definition.recurring.pairs, currentDate)
            .flat()
            .concat(
              getRecurringDatesForMonth(
                fixedNudgeSchedule.definition.recurring.pairs,
                dayjs(currentDate).subtract(1, 'month').toDate()
              ).flat()
            )
            .concat(
              getRecurringDatesForMonth(
                fixedNudgeSchedule.definition.recurring.pairs,
                dayjs(currentDate).add(1, 'month').toDate()
              ).flat()
            )
            .map((date) =>
              dayjs(date.toISOString()).utcOffset(fixedNudgeSchedule.definition.tz).format('YYYY-MM-DDTHH:mm:ss.SSS')
            )
        )
      ).sort();
    }
  }, [fixedNudgeSchedule, currentDate]);

  const handleSchedulingUpdate = useCallback(async () => {
    if (!fixedNudgeSchedule) {
      return;
    }

    try {
      schedulingService.update(fixedNudgeSchedule.id, schedulingRef.current.value().definition);
      setEditing(false);
    } catch (err) {
      console.log(err);
    }
  }, [schedulingRef, fixedNudgeSchedule]);

  return (
    <>
      {editing && (
        <CFModal onClose={() => setEditing(false)}>
          <div>
            <SchedulingBuilder
              ref={schedulingRef}
              editing={true}
              defaultValue={fixedNudgeSchedule}
              onReady={() => ({})}
            />

            <div className="scheduling-edit-controls">
              <CFButton role={CFRole.Secondary} value={'Cancel'} onClick={() => setEditing(false)} />
              <CFButton role={CFRole.Primary} value={'Update'} onClick={handleSchedulingUpdate} />
            </div>
          </div>
        </CFModal>
      )}

      <CFTitledSection title="Scheduling" subtitle={schedulingSubTitle} className={`${className} schedule-definition`}>
        {isAllowedTo(AuthAction.CreateIntervention) ? (
          <SectionAction>
            <CFEditButton onClick={() => setEditing(true)} />
          </SectionAction>
        ) : (
          <></>
        )}
        <div className="calendar-with-week">
          <Month
            value={currentDate}
            date={selectedDate}
            minDate={moment(0).toDate()}
            maxDate={dayjs().add(1, 'year').toDate()}
            setValue={() => ({})}
            timestamps={timestamps}
            helpers={{
              isHover: () => false,
            }}
            handlers={{
              onDayClick: handleClickOnDay,
              onDayHover: () => ({}),
              onMonthNavigate: handleMonthNavigate,
            }}
          />
          <WeekViewer week={week} year={year} timestamps={timestamps} />
        </div>
      </CFTitledSection>
    </>
  );
};

export default Schedule;
