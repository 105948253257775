import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import CFAddButton from 'components/buttons/CFAddButton';
import { NavigationAction } from 'components/DateTime/DatetimeRangePicker/types';
import TimePicker from 'components/DateTime/TimePicker';
import CFButtonGroup from 'components/CFButtonGroup';
import CFSelect from 'components/CFSelect';

import { FixedNudgeSchedule, Schedule, ScheduleType, StartEndPair } from 'services/intervention/intervention.types';
import { displayDate, getRecurringDatesForMonth, overlaps } from 'helpers/dates';

import Month from './month';
import ScheduleSet from './ScheduleSet';

import timezones from './timezones.json';

import './scheduling.scss';

const defaultTypeOptions = [
  {
    label: 'Calendar',
    value: ScheduleType.TimePoints,
  },
  {
    label: 'Recurring',
    value: ScheduleType.Recurring,
  },
];

const SET_COLORS = [
  {
    color: 'rgba(148, 213, 219, 1)',
    backgroundColor: 'rgba(44, 57, 65, 1)',
  },
  {
    color: 'rgba(251, 188, 5, 1)',
    backgroundColor: 'rgba(251, 188, 5, 0.2)',
  },
  {
    color: 'rgba(240, 113, 107, 1)',
    backgroundColor: 'rgba(240, 113, 107, 0.2)',
  },
];

export interface InterventionSchedulingRef {
  value: () => Schedule;
}

interface Props {
  defaultValue?: FixedNudgeSchedule;
  editing?: boolean;
  onReady: (ready: boolean) => void;
}

const SchedulingBuilder = forwardRef<InterventionSchedulingRef, Props>(function InterventionScheduling(
  { editing = false, onReady, defaultValue }: Props,
  ref
) {
  const today = new Date();

  const defaultScheduleSetValues = defaultValue?.definition.recurring?.pairs.reduce<
    Record<string, StartEndPair | null>
  >((acc, val, idx) => {
    acc[new Date().valueOf() + idx] = val;

    return acc;
  }, {});

  const [scheduleSetValues, setScheduleSetValues] = useState<Record<string, StartEndPair | null>>(
    defaultScheduleSetValues || {
      [new Date().valueOf()]: null,
    }
  );

  const defaultStartTime =
    defaultValue?.definition?.time_points?.pts?.[0] &&
    dayjs(defaultValue.definition.time_points.pts[0]).format('HH:mm');

  const [startTime, setStartTime] = useState(defaultStartTime || '00:00');
  const [timezone, setTimezone] = useState(dayjs.tz.guess());

  const scheduleTypeOptions = useMemo(() => {
    if (!defaultValue) {
      return defaultTypeOptions;
    }

    if (!defaultValue.definition.recurring) {
      return defaultTypeOptions.filter((item) => item.value === ScheduleType.TimePoints);
    }

    if (!defaultValue.definition.time_points) {
      return defaultTypeOptions.filter((item) => item.value === ScheduleType.Recurring);
    }

    return defaultTypeOptions;
  }, [defaultValue]);

  const formatDate = useCallback(
    (date: Date) => {
      return displayDate(date, startTime, true, false, timezone, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
    [startTime, timezone]
  );

  const defaultTypeOption =
    defaultValue?.type && scheduleTypeOptions.find((option) => option.value === defaultValue?.type);
  const defaultCalendarDates = defaultValue?.definition?.time_points?.pts?.map((pts) =>
    formatDate(dayjs(pts).toDate())
  );

  const [currentMonth, setCurrentMonth] = useState<Date>(today);
  const [selectedDate, setSelectedDate] = useState<Date>(today);
  const [hoverDay, setHoverDay] = useState<Date>();
  const [scheduleType, setScheduleType] = useState(defaultTypeOption || scheduleTypeOptions[0]);
  const [calendarDates, setCalendarDates] = useState<string[]>(defaultCalendarDates || []);

  const handleAddMoreSlots = () => {
    setScheduleSetValues({ ...scheduleSetValues, [new Date().valueOf()]: null });
  };

  const handleDeleteSlot = useCallback(
    (id: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [id]: _, ...rest } = scheduleSetValues;
      setScheduleSetValues(rest);
    },
    [scheduleSetValues]
  );

  const onDayClick = useCallback((day: Date) => {
    setSelectedDate(day);
  }, []);

  const onDayHover = useCallback(
    (date: Date) => {
      if (!hoverDay || !dayjs(date).isSame(hoverDay)) {
        setHoverDay(date);
      }
    },
    [hoverDay]
  );

  const onMonthNavigate = useCallback(
    (action: NavigationAction) => {
      const newMonth = dayjs(currentMonth).add(action, 'month').toDate();
      setCurrentMonth(newMonth);
    },
    [currentMonth]
  );

  const isHover = useCallback(
    (day: Date): boolean => {
      return !!hoverDay && dayjs(hoverDay).isSame(day, 'day');
    },
    [hoverDay]
  );

  const helpers = useMemo(() => ({ isHover }), [isHover]);

  const handleSetChange = useCallback(
    (id: string) => (pair: StartEndPair) => {
      setScheduleSetValues({ ...scheduleSetValues, [id]: pair });
    },
    [scheduleSetValues]
  );

  const toggleCalendarDate = useCallback(
    (date: Date) => {
      const formattedDate = formatDate(date);

      if (editing && date < new Date()) {
        return;
      }
      /* 
        Compare only the date/time part, without timezone. the user might change the timezone
        during the usage and, in that case, comparing whole string does not work, because the 
        time zone may differ.

        To improve this, encapsulate dates in an object and add comparison capabilities to it
      */
      const newDates = calendarDates.filter((_date) => _date.slice(0, -6) !== formattedDate.slice(0, -6));

      if (newDates.length === calendarDates.length) {
        newDates.push(formattedDate);
      }

      setCalendarDates(newDates);
    },
    [calendarDates, formatDate, timezone]
  );

  const handlers = useMemo(
    () => ({
      onDayClick: toggleCalendarDate,
      onDayHover,
      onMonthNavigate,
    }),
    [onDayClick, onDayHover, onMonthNavigate]
  );

  const value = useMemo(
    () =>
      ({
        type: scheduleType.value,
        definition: {
          tz: dayjs.tz(new Date(), timezone).format('Z'),
          ...(scheduleType.value === ScheduleType.Recurring && {
            recurring: { pairs: Object.values(scheduleSetValues) },
          }),
          ...(scheduleType.value === ScheduleType.TimePoints && { time_points: { pts: calendarDates } }),
        },
      } as Schedule),
    [scheduleType, scheduleSetValues, calendarDates, timezone]
  );

  useEffect(() => {
    const isReady =
      (value.type === ScheduleType.TimePoints && value.definition.time_points?.pts.length !== 0) ||
      (value.type === ScheduleType.Recurring &&
        value.definition.recurring?.pairs.length !== 0 &&
        !overlaps(
          value.definition.recurring?.pairs.filter((schedule): schedule is StartEndPair => schedule !== null) || []
        ));

    onReady(isReady);
  }, [value]);

  useEffect(() => {
    setCalendarDates(calendarDates.map((date) => formatDate(new Date(date))));
  }, [startTime]);

  useImperativeHandle(ref, () => ({
    value: () => value,
  }));

  const minDateValid = dayjs(today).subtract(10, 'years').toDate();
  const maxDateValid = dayjs(today).add(10, 'years').toDate();

  return (
    <>
      <CFButtonGroup
        className="intervention-schedule-type"
        options={scheduleTypeOptions}
        value={scheduleType}
        onSelect={setScheduleType}
      />
      {scheduleType.value === ScheduleType.Recurring ? (
        <div className="intervention-scheduling">
          <div className="intervention-scheduling-calendar">
            <Month
              selectedDate={selectedDate}
              minDate={minDateValid}
              maxDate={maxDateValid}
              helpers={helpers}
              handlers={{
                ...handlers,
                onDayClick: () => void 1,
              }}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              highlightedDates={getRecurringDatesForMonth(
                Object.values(scheduleSetValues).filter((schedule): schedule is StartEndPair => schedule !== null),
                currentMonth
              )
                .map((populatedPair, i) => {
                  return populatedPair.map((date) => {
                    return {
                      date,
                      textColor: SET_COLORS[i].color,
                      backgroundColor: SET_COLORS[i].backgroundColor,
                    };
                  });
                })
                .flat()}
            />
          </div>

          <div className="intervention-scheduling-sets">
            <div className="group timezone">
              <div className="text-md">Timezone</div>
              <CFSelect
                options={Object.entries(timezones).map(([k, v]) => ({ value: v, label: k }))}
                value={{ value: timezone, label: timezone }}
                onSelected={(option) => setTimezone(option.value)}
                disabled={defaultValue !== undefined}
              />
            </div>

            {Object.entries(scheduleSetValues).map(([slotTs, defaultValueItem], i) => (
              <ScheduleSet
                key={slotTs}
                id={i + 1}
                defaultValue={defaultValueItem || undefined}
                onChange={handleSetChange(slotTs)}
                onRemove={() => handleDeleteSlot(slotTs)}
                timezone={timezone}
                editable={
                  defaultValue && defaultValue.definition.recurring
                    ? i >= defaultValue.definition.recurring.pairs.length
                    : true
                }
              />
            ))}

            <CFAddButton value="Add schedules" onClick={handleAddMoreSlots} />
          </div>
        </div>
      ) : (
        <div className="intervention-scheduling">
          <div className="intervention-scheduling-calendar">
            <Month
              selectedDate={selectedDate}
              minDate={minDateValid}
              maxDate={maxDateValid}
              helpers={helpers}
              handlers={handlers}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              highlightedDates={calendarDates.map((date) => ({ date: new Date(date) }))}
            />
          </div>
          <div className="intervention-scheduling-calendar">
            <div className="set-title">Selected Dates</div>
            {calendarDates.length !== 0 && (
              <div className="selected-dates" data-testid="ttt123">
                {calendarDates.map((date) => (
                  <div key={date.toString()} className="selected-date">
                    {dayjs(date).format('ddd DD-MM-YY')}
                    {new Date() < new Date(date) && (
                      <FontAwesomeIcon
                        className="selected-date-cross"
                        onClick={() => toggleCalendarDate(new Date(date))}
                        icon={icon({ name: 'xmark', style: 'solid' })}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}

            <div className="group timezone calendar">
              <div className="text-md">Timezone</div>
              <CFSelect
                options={Object.entries(timezones).map(([k, v]) => ({ value: v, label: k }))}
                value={{ value: timezone, label: timezone }}
                onSelected={(option) => setTimezone(option.value)}
                disabled={true}
              />
            </div>
            <div className="group">
              <div className="text-md">Start time</div>
              <TimePicker defaultValue={startTime} onChange={setStartTime} disabled={editing} />
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default SchedulingBuilder;
