import { TraitSubject } from 'domain/traits.types';
import { BriefCohort, FilterAPI, Ptr } from 'services/cohort/cohort.types.api';
import { Module, TimeRFC3999 } from '../../domain/general.types';

export enum AssistantMessageType {
  Question = 'question',
  ValidationRequest = 'validation-request',
  ValidationResponse = 'validation-response',
  Answer = 'answer',
  Error = 'error',
}

export interface AssistantMessage {
  id: number;
  tid: number;
  parentId?: number;
  questionId?: number;
  type: AssistantMessageType;
  steps?: string[];
  text: string;
  structuredText?: Record<string, string>[];
  timestamp: TimeRFC3999;
  payload?: Payload;
  error: boolean;
  reason?: string;
  substitutions?: Substitutions[];
  meta?: {
    modelData: ModelMsg[];
  };
}

export interface Substitutions {
  ptr: Ptr;
  key: string;
  options: string[];
}

export enum CFMessageRole {
  User = 'user',
  Assistant = 'assistant',
}

export interface CFMessage {
  id: number;
  tid: number;
  thread_id: number;
  parent_id: number;
  role: CFMessageRole;
  content: string;
  payload: Payload;
  time: TimeRFC3999;
}

export interface PayloadParsedArgs {
  filters?: FilterAPI[] | null;
  [anyKey: string]: any;
}

export interface Payload {
  query: string;
  modules: string[];
  subjects: string[];
  [key: string]: boolean | string | string[];
}

export interface HintMessage {
  title: string;
  text: string;
}

export type CFQuestionResponse = RunId;

export type RunId = string;

interface ModelMsg {
  role: string;
  content: string;
}

export interface SelectedOptionAPI {
  ptr: Ptr;
  search_key: string;
  selected: string;
}

export interface OptionAPI {
  ptr: Ptr;
  search_key: string;
  search_results: string[];
}

interface CtxAPI {
  today: TimeRFC3999;
}

interface QuestionAPI {
  parent_msg_id?: number;
  msg: string;
  ctx: CtxAPI;
}

export interface ChatWithValidationReq {
  question: QuestionAPI;
  payload?: Payload;
  selected_options?: SelectedOptionAPI[];
  model_msgs?: ModelMsg[];
}

export interface ChatWithValidationResp extends CFMessage {
  payloads: Payload[];
  options: OptionAPI[];
  model_msgs: ModelMsg[];
}

export interface ChatAPIResponse {
  msg: ChatWithValidationResp;
  question_id: number;
}

export interface TraitExploreResp {
  subject: TraitSubject;
  module: Module;
  cohorts: BriefCohort[];
  traits: Ptr[];
}
