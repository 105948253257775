import { TimeRFC3999 } from 'domain/general.types';
import { findDeletedInArray, findNewInArray } from 'helpers/arrays';
import { ScheduleDefinition } from 'services/intervention/intervention.types';
import { ScheduleUpdateReq, StartEndPair } from './schedulting.types.api';

export const transformRecurring = (
  oldScheduling: ScheduleDefinition,
  newScheduling: ScheduleDefinition
): ScheduleUpdateReq => {
  const newPairsCount = (newScheduling.recurring?.pairs.length || 0) - (oldScheduling.recurring?.pairs.length || 0);

  let newPairs: StartEndPair[] = [];

  if (newPairsCount !== 0) {
    newPairs = newScheduling.recurring?.pairs.slice(-newPairsCount) || [];
  }

  const pairsToEdit = newScheduling.recurring?.pairs
    .slice(0, newScheduling.recurring?.pairs.length - newPairsCount)
    .map((item, idx) => ({
      idx,
      delete: false,
      new_end: item.end as TimeRFC3999,
    }));

  return {
    recurring_req: {
      pairs_to_edit: pairsToEdit || [],
      pairs_to_add: newPairs || [],
    },
  };
};

export const transformPoints = (
  oldScheduling: ScheduleDefinition,
  newScheduling: ScheduleDefinition
): ScheduleUpdateReq => {
  oldScheduling.time_points?.pts;

  // reason of this:
  //    backend format is: 2024-10-01T00:00:00+02:00
  //    SchedulerBuilder format is: 2024-10-01T00:00:00.000+02:00 <-- this should be changed
  const formattedNewPoints = newScheduling.time_points?.pts.map(
    (timePoint) => `${timePoint.slice(0, -10)}${timePoint.slice(-6)}`
  );

  return {
    time_points_req: {
      to_add: findNewInArray(oldScheduling.time_points?.pts || [], formattedNewPoints || []),
      to_delete: findDeletedInArray(oldScheduling.time_points?.pts || [], formattedNewPoints || []),
    },
  };
};
