import React from 'react';

import CFLineChart, { ScaleType } from 'components/charts/CFLineChart';
import { CensoringMetrics, CensoringScores, ModelMetrics } from 'domain/model.types';
import { AggLevel, Series } from 'domain/stats.types';
import { useMemo } from 'react';
import { chartLineColor1, CHART_COLORS } from 'styles/colors';

interface Props {
  modelMetrics: ModelMetrics | undefined;
  type: 'cindex' | 'cindex_ipcw';
  description: string;
}

const ScoreTimeSerie = ({ modelMetrics, type, description }: Props) => {
  console.log('[aec] modelMetrics: ', modelMetrics);

  const cIndexData: Series[] = useMemo(() => {
    if (!modelMetrics) {
      return [];
    }

    const timeseriesKeys = Object.keys(modelMetrics?.censoring).filter((key) => key.endsWith('_scores'));

    return timeseriesKeys
      .map((key, i) => ({
        name: key.slice(0, -'_scores'.length),
        items:
          ((modelMetrics.censoring[key as keyof CensoringMetrics] as CensoringScores)[type] || []).map((point) => ({
            time: point.t,
            value: point.v,
          })) || [],
        color: CHART_COLORS[i] || CHART_COLORS[0],
      }))
      .filter((split) => split.items.length !== 0);
  }, [modelMetrics]);

  const thereIsData = cIndexData.map((data) => data.items).flat().length !== 0;

  if (!thereIsData) {
    return <div></div>;
  }

  return (
    <CFLineChart
      scale={ScaleType.Linear}
      title={type === 'cindex' ? 'C-Index' : 'C-Index IPCW'}
      color={chartLineColor1}
      data={cIndexData}
      aggregationLevel={AggLevel.Day}
      isLoading={false}
      step={0.1}
      decimalPlaces={5}
      description={description}
      forceSingleLegend={true}
    />
  );
};

export default ScoreTimeSerie;
