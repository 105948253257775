import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useCFNavigation from 'hooks/useCFNavigation';
import { useServicesContext } from 'hooks/useServicesContext';

import { CFRoutes } from 'routes';

import { TraitSubject, TraitUsage } from 'domain/traits.types';

import { AlgorithmType, InterventionViewExtended } from 'services/intervention/intervention.types';

import InterventionDetail from 'views/intervention/components/InterventionDetail';

import { CFNavList } from 'components/CFNavList';
import CFTitledSection from 'components/CFTitledSection';

import Schedule from './schedule';
import CohortDefinition from './cohort';
import GroupsDefinition from './groups';
import { monitoringByTab, Tabs } from '../tabs';
import { ABMetricsViewer, BanditMetricsViewer } from './metrics';
import ManualSample from './manualSample';
import MarkovStatesDefinition from './markov';

import './definition.scss';

const InterventionDefinition = () => {
  const { cohortService, interventionService, traitSessionService: traitService } = useServicesContext();

  const navigate = useCFNavigation();

  const [interventionView, setInterventionView] = useState<InterventionViewExtended>();
  const params = useParams();

  useEffect(() => {
    (async () => {
      try {
        const view = await interventionService?.getView(parseInt(params.id as string));

        if (!view) {
          return;
        }
        // in AB testing with manually picked users, cohort is not set, so we use User as default
        await traitService.getTraits({
          subject: view.cohort.subject_type || TraitSubject.User,
          usage: TraitUsage.Cohort,
        });

        setInterventionView(view);
      } catch (err) {
        navigate(CFRoutes.intervention);
      }
    })();
  }, [interventionService]);

  if (!interventionView) {
    return <div></div>;
  }

  return (
    <div className="intervention-monitor-definition">
      <CFNavList
        titles={Object.values(Tabs)}
        selected={Tabs.Definition}
        onClick={(selectedTab) => navigate(monitoringByTab[selectedTab].replace(':id', params.id as string))}
      />
      <div className="intervention-definition-container">
        <CFTitledSection
          title={`Intervention #${interventionView.intervention.id}: ${interventionView.intervention.name}`}
        >
          <InterventionDetail interventionService={interventionService} id={interventionView.intervention.id} />
        </CFTitledSection>

        {interventionView.intervention.algo_policy.type === AlgorithmType.Bandit && (
          <BanditMetricsViewer intervention={interventionView} traitService={traitService} className="metrics" />
        )}

        {interventionView.intervention.algo_policy.type === AlgorithmType.ABTest && (
          <ABMetricsViewer intervention={interventionView} traitService={traitService} className="metrics" />
        )}

        <Schedule scheduleId={interventionView.schedule.id} className={'schedule'} />
        {interventionView.cohort.id !== 0 ? (
          <CohortDefinition
            sampleSize={interventionView.sampleSize || -1}
            extraFilters={interventionView.intervention.participant_policy.static_sample.extra_filters}
            cohort={interventionView.cohort}
            cohortService={cohortService}
            traitService={traitService}
            className="cohort"
            sampleId={interventionView.intervention.participant_policy.static_sample.sample_id}
          />
        ) : (
          <ManualSample
            sampleSize={interventionView.sampleSize || -1}
            className="cohort"
            sampleId={interventionView.intervention.participant_policy.static_sample.sample_id}
          />
        )}

        {interventionView.intervention.algo_policy.type === AlgorithmType.RestlessBandit && (
          <>
            <MarkovStatesDefinition intervention={interventionView} />
          </>
        )}

        <GroupsDefinition intervention={interventionView} className="groups" />
      </div>
    </div>
  );
};

export default InterventionDefinition;
